export default [
    {
        path: 'vacation-statistics',
        meta: { label: 'Vacation Statistics Report', permission: 'show_reports_vacation_statistics' },
        name: 'vacation-statistics-report',
        component: () => import('./../../views/reports/VacationStatisticsReport'),
    },
    {
        path: 'vacation',
        meta: { label: 'Vacation Report', permission: 'show_reports_vacation_details' },
        name: 'vacation-report',
        component: () => import('./../../views/reports/VacationsReport'),
    },
    {
        path: 'expense',
        meta: { label: 'Expense Report', permission: 'show_reports_expense_details' },
        name: 'expense',
        component: () => import('../../views/reports/ExpenseReport.vue'),
    },
    {
        path: 'expense-statistics',
        meta: { label: 'Expense Statistics', permission: 'show_reports_expense_statistics' },
        name: 'expense-statistics',
        component: () => import('../../views/reports/ExpenseStatisticsReport.vue'),
    },
    {
        path: 'expense-statistics-version2',
        meta: { label: 'Expense Statistics', permission: 'show_reports_expense_statistics_v2' },
        name: 'expense-statistics-second-version',
        component: () => import('../../views/reports/ExpenseStatisticsReportV2.vue'),
    },
    {
        path: 'end-commercial/:id',
        meta: { label: 'Commercial Details', permission: 'show_reports_expense_statistics' },
        name: 'showFinance',
        component: () => import('../../views/commercialrequest/showFinance.vue'),
    },
    {
        path: 'expense-stats/:id/edit',
        meta: { label: 'Edit Expense', permission: 'edit_expenses' },
        name: 'edit-expense-stats',
        component: () => import('../../components/expenses/EditExpense.vue')
    },
    {
        path: 'commercial-statistics',
        meta: { label: 'Commercial Statistics', permission: 'show_reports_commercial_statistics' },
        name: 'commercial-statistics',
        component: () => import('../../views/reports/CommercialStatisticsReport.vue'),
    },
    {
        path: 'commercial-details',
        meta: { label: 'Commercial Details', permission: 'show_reports_commercial_details' },
        name: 'commercial-details',
        component: () => import('../../views/reports/CommercialReport.vue'),
    },
    {
        path: 'commercial-bills',
        meta: { label: 'Commercial Bills', permission: 'show_reports_commercial_bills' },
        name: 'commercial-bills',
        component: () => import('../../views/reports/CommercialBillsReport.vue'),
    },
    {
        path: 'commercial-costs',
        meta: { label: 'Commercial Costs', permission: 'show_reports_commercial_costs' },
        name: 'commercial-costs',
        component: () => import('../../views/reports/CommercialCostsReport.vue'),
    },
    {
        path: 'end-payment',
        meta: { label: 'End Commercial Payment', permission: 'show_reports_end_commercial_payments' },
        name: 'end-payment',
        component: () => import('../../views/reports/EndCommercialPaymentReport.vue'),
    },
    {
        path: 'end-payment',
        meta: { label: 'Finance Summary', permission: 'show_reports_finance_summary' },
        name: 'finance-summery',
        component: () => import('../../views/reports/FinanceSummaryReport.vue'),
    },

    {
        path: 'custody-report',
        meta: { label: 'Custody Report', permission: 'show_reports_custody' },
        name: 'custody-report',
        component: () => import('../../views/reports/CustodyReport.vue'),
    },
    {
        path: 'budget',
        meta: { label: 'Budget Details', permission: 'show_reports_budget_details' },
        name: 'budget-report',
        component: () => import('../../views/reports/BudgetReport.vue'),
    },
    {
        path: 'budget-consumptions',
        meta: { label: 'Budget Consumptions', permission: 'show_reports_budget_consumptions' },
        name: 'budget-consumptions',
        component: () => import('../../views/reports/BudgetConsumptionReport.vue'),
    },
    {
        path: 'budget-analysis',
        meta: { label: 'Budget Analysis', permission: 'show_reports_budget_analysis' },
        name: 'budget-analysis',
        component: () => import('../../views/reports/BudgetAnalysisReport.vue'),
    },
    {
        path: 'material-statistics',
        meta: { label: 'Material Statistics', permission: 'show_reports_material_statistics' },
        name: 'material-statistics',
        component: () => import('../../views/reports/MaterialStatisticsReport.vue'),
    },
    {
        path: 'material-report',
        meta: { label: 'Material Details' },
        name: 'material-report',
        component: () => import('../../views/reports/MaterialReport.vue'),
    },
    {
        path: 'material-costs',
        meta: { label: 'Material Costs', permission: 'show_reports_material_costs' },
        name: 'material-costs',
        component: () => import('../../views/reports/MaterialCostsReport.vue'),
    },
    {
        path: 'order-requests',
        meta: { label: 'Order Requests', permission: 'show_reports_order_requests' },
        name: 'order-requests',
        component: () => import('../../views/reports/OrderRequestReport.vue'),
    },
    {
        path: 'finance-orders',
        meta: { label: 'Finance Order Requests', permission: 'show_reports_finance_orders' },
        name: 'finance-orders',
        component: () => import('../../views/reports/FinanceOrdersReport.vue'),
    },
];
