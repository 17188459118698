

export default [

  {
    path: '/grouping-settings',
    meta: { label: 'General Settings' },
    component: {
      render(c) { return c('router-view') }
    },
    children: [
      {
        path: '',
        name: 'Grouping Settings',
        meta: {
          label: 'Grouping Settings',
          permission: 'show_settings_grouping_settings'
        },
        component: () => import('./../../views/groupings/settings/index.vue'),
      },
      {
        path: 'create',
        meta: { label: 'Grouping Setting Create', permission: 'show_settings_grouping_settings' },
        name: 'CreateGroupingSetting',
        component: () => import('./../../views/groupings/settings/create.vue'),
      },
      {
        path: ':id',
        meta: { label: 'Grouping Setting Details', permission: 'show_settings_grouping_settings' },
        name: 'GroupingSetting',
        component: () => import('./../../views/groupings/settings/show.vue'),
      },
      {
        path: ':id/edit',
        meta: { label: 'Edit Grouping Setting', permission: 'show_settings_grouping_settings' },
        name: 'EditGroupingSetting',
        component: () => import('./../../views/groupings/settings/edit.vue')
      },

    ]
  },
  {
    path: '/grouping-lines',
    meta: { label: 'General Settings' },
    component: {
      render(c) { return c('router-view') }
    },
    children: [
      {
        path: '',
        name: 'Grouping Account Lines',
        meta: {
          label: 'Grouping Account Lines',
          permission: 'show_all_grouping_account_lines'
        },
        component: () => import('./../../views/groupings/AccountLines/index.vue'),
      },
    ]
  },
];
